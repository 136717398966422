@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';









































.select-wrapper.select-wrapper--inputSelect {
  right: 0;
  top: 50%;
  transform: translate(95%, -51%);
  bottom: auto;
}

@import '@/assets/scss/_variables.scss';

.GridTable {
  &_cell {
    .GridTable_row--head & {
      border-bottom: none;
      padding-bottom: 0;
      padding-top: 0;

      @media only screen and (min-width: $tablet-width) {
        font-weight: 500;
      }
    }
  }

  &_button {
    margin: 0 2px 0 0;

    @media only screen and (min-width: $tablet-width) {
      margin: 0 6px 0 0;
    }

    .GridTable_Buttons & {

      &.Button_defaultBtn,
      &.Button_brightBtn {
        min-width: 16px;

        @media only screen and (min-width: $tablet-width) {
          min-width: 84px;
        }

        &.Button_small {
          line-height: 16px;

          @media only screen and (min-width: $tablet-width) {
            line-height: 30px;
          }
        }
      }
    }

    &:last-of-type {
      margin: 0;
    }

    &--withdraw {
      &:before {
        content: "-";
        @media only screen and (min-width: $tablet-width) {
          display: none;
        }
      }
    }

    &--deposit {
      &:before {
        content: "+";
        @media only screen and (min-width: $tablet-width) {
          display: none;
        }
      }
    }

    span {
      display: none;

      @media only screen and (min-width: $tablet-width) {
        display: inline;
      }
    }
  }
}

@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';































































































.toggle-btn-text {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #2af3f3 transparent transparent transparent;
  }

  &.toggle-btn-text-active {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
.dropdown-filter {
  opacity: 0;
  visibility: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.ProvidersTable_Filter {
  position: relative;
  text-align: left;

  &Button {
    width: 100%;
  }
  &Dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 260px;
    background: #2D4D70;
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
    border-radius: 4px;
    margin: 12px 0 0;
    opacity: 0;
    visibility: hidden;

    &:before {
      content: '';
      width: 100%;
      height: 12px;
      top: 100%;
      left: 0;
      position: absolute;
    }

    .ProvidersTable_Filter--open & {
      opacity: 1;
      visibility: visible;
    }

    &Head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #A6AEBF;
      height: 46px;
      padding: 8px 10px;
    }

    &Title{
      font-size: 12px;
      line-height: 14px;
      color: #fff;
    }

    &Done{
      display: inline-flex;
      justify-content:center;
      align-items:center;
      text-align: center;
      padding: 3px 6px;
      background: rgba(#2AF3F3, .1);
      border-radius: 1.6px;
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
      color: #2AF3F3;
      cursor: pointer;
    }

    &List{
      &Item{
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid rgba(#2AF3F3, .21);

        &:last-child{
          border-bottom-color: transparent;
        }

        &--open{
          display: block;
          cursor: default;
        }

        &Title{
          font-size: 12px;
          line-height: 14px;
          color: #fff;
          text-transform: uppercase;

          .ProvidersTable_FilterDropdownListItem--open & {
            color: #2AF3F3;
            margin: 0 0 4px;
          }
        }

        &Arrow{
          width: 10px;

          svg {
            transform: rotate(90deg);
            width: 100%;
            height: 100%;
            display: block;
          }

          .ProvidersTable_FilterDropdownListItem--open & {
            display: none;
          }
        }

        &Select{
          display: none;

          .ProvidersTable_FilterDropdownListItem--open & {
            display: block;
          }
        }
      }
    }
  }
}
